<template>
	<div class="mb-20">
		<div class="flex justify-end">
			<search-form
				v-model="searchQuery"
				class="mb-5 w-full sm:w-1/2"
				placeholder="Search for user's name, status, etc."
				@submit="$refs.table.loadAjaxData()"
			/>
		</div>
		<div class="flex flex-col border border-solid border-blue-200">
			<div class="duration-300">
				<datatable
					:url="url"
					:ajax="true"
					:ajaxPagination="true"
					:index="true"
					:reverse-index="true"
					:fillable="true"
					:filters="filters"
					dropdown="actions"
					:pageDetails="true"
					:exportable="true"
					:data="manualUsers.data"
					:columns="columns"
					:query="searchQuery"
					:onClick="click"
					:actions="actions"
					:loading="manualUsers.loading"
					ref="table"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import moment from "moment";
export default {
	props: {
		query: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			searchQuery: "",
			rowFilters: "filters",
			manualUsers: this.$options.resource([]),
			url: `${this.$baseurl}/admin/personal/verification-method/manual`,
			columns: [
				{
					name: "name",
					th: "Name",
					render: (manual) => {
						return `${manual?.user?.name} ${manual?.user?.last_name}`;
					},
				},
				{
					name: "email",
					th: "Email",
					render: (manual) => manual?.user?.email,
				},
				{
					name: "created_at",
					th: "Date Registered",
					render: (express) => {
						return moment(express?.created_at).format("MMM Do YYYY");
					},
				},
				{
					name: "progress",
					th: "Progress",
					render: (express) => {
						return this.progressIcons(express?.user);
					},
				},
				{
					name: "status",
					th: "Status",
					render: (manual, status) => {
						switch (status) {
							case "pending":
								return `<div class="badge badge-orange-soft-outline px-7">
									Pending
								  </div>`;
							case "verified":
								return `<div class="badge badge-blue-soft-outline px-7">
								Verified
							  </div>`;
							case "completed":
								return `<div class="badge badge-green-soft-outline px-7">
								Completed
							  </div>`;
							default:
								return `------`;
						}
					},
				},
				{
					name: "profile",
					th: "Profile Status",
					render: (express) => {
						return this.profileBadge(express?.user?.profile?.status);
					},
				},
			],
			filters: [
				{ title: "all", name: "all" },
				{ title: "processing", name: "processing" },
				{ title: "pre-approved", name: "pre-approved" },
				{ title: "approved", name: "approved" },
				{ title: "uncertain", name: "uncertain" },
			],
			actions: [
				{
					text: "mark as completed",
					class:
						"border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200",
					action: this.completed,
					disabled: (manual) => {
						return manual?.status === "completed";
					},
				},
			],
		};
	},
	methods: {
		click(selected) {
			return this.$router.push({
				name: "staff-view",
				params: {
					staffId: selected?.user?.id,
				},
			});
		},
		completed(selected) {
			this.$get({
				url: `${this.$baseurl}/admin/personal/verification-method/complete/${selected.id}`,
				headers: this.headers,
				success: () => (this.$refs.table.loadAjaxData()),
				error: (error) => {
					console.log(error);
				},
			});
		},
	},
};
</script>